<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          to="/"
          class="nav-link align-items-center"
          @click="toggleVerticalMenuActive"
        >
          <!-- <div class="d-flex w-100 align-items-center">
            <span class="bg-primary d-inline-block p-1 rounded-circle" /> &nbsp;&nbsp;
            <span class="d-inline-block font-weight-normal brand">Survivor Pool</span>
          </div> -->
          <div class="d-flex w-100 align-items-center">
            <b-img
              :src="LogoImg"
              class="d-inline-block navbar-logo brand"
            />
          </div>
        </b-link>
      </li>
    </ul>

    <b-navbar-nav
      v-if="loggedIn"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.first_name }} {{ user.last_name }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="user.avatar"
          />
          <!--
            badge
            class="badge-minimal"
            badge-variant="success"
          -->
        </template>

        <b-dropdown-item
          :to="{ name: 'profile'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-nav
      v-else
      class="ml-auto"
    >
      <b-nav-item :to="{ name: 'login' }">
        Login
      </b-nav-item>
      <b-nav-item :to="{ name: 'register' }">
        Register
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import {
  BImg, BLink, BNav, BNavItem, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { isUserLoggedIn } from '@/auth/utils'
import LogoImgSrc from '@/assets/images/logo/logo-title.png'

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNav,
    BNavItem,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loggedIn: false,
      user: {
        name: 'John Doe',
      },
      LogoImg: LogoImgSrc,
    }
  },
  mounted() {
    this.loggedIn = isUserLoggedIn()
    if (this.loggedIn) {
      this.user = JSON.parse(localStorage.getItem('userData'))
    }
    // catch the user logged in event
    this.$root.$on('userLoggedIn', () => {
      this.loggedIn = true
      this.user = JSON.parse(localStorage.getItem('userData'))
    })
    this.$root.$on('userLoggedOut', () => {
      this.loggedIn = false
      this.user = {}
    })
  },
  methods: {
    logout() {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$router.replace('/login')
      window.location.reload()
      this.$root.$emit('userLoggedOut')
    },
  },
}
</script>
