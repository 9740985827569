<template>
  <p class="clearfix mb-0">
    <!--  Add three links in nav, centered and justified space between named Terms, Privacy, Data  -->
    <b-nav align="right">
      <b-nav-item :to="{ name: 'home' }">
        <b-button
          variant="primary"
          class="btn-icon"
        >
          Today's Question
        </b-button>
      </b-nav-item>

      <b-nav-item :to="{ name: 'history' }">
        <b-button
          variant="primary"
          class="btn-icon"
        >
          History
        </b-button>
      </b-nav-item>

      <b-nav-item-dropdown
        id="nav-dropdown-settings"
        text="Settings"
        variant="link"
        no-caret
        right
      >
        <template #button-content>
          <b-button
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="MenuIcon" />
          </b-button>
        </template>
        <b-dropdown-item :to="{ name: 'terms' }">
          Terms
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'privacy' }">
          Privacy
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'data' }">
          Data
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'contact' }">
          Contact
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-nav>
  </p>
</template>

<script>
import {
  BButton, BNav, BNavItem, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
  },
}
</script>
